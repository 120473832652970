import { useState } from "react";

import TableAction from "components/TableAction";
import MDBox from "components/MDBox";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useNavigate } from "react-router-dom";
import { useAppContextController } from "context/AppContext";

const MyJobsActions = ({ slug }) => {
  const navigate = useNavigate();

  const { company } = useAppContextController();

  const onClickHandler = (action, e) => {
    e.stopPropagation();
    if (action === "jobstatus" && company?.dashboardJobStatusFullscreen !== "Yes")
      navigate(`/companyjobs/${slug}/action/jobinfo?panel=status`);
    else navigate(`/companyjobs/${slug}/action/${action}`);
  };

  const [actions, setActions] = useState([
    {
      color: "info",
      icon: "edit",
      clickAction: (e) => onClickHandler("jobinfo", e),
      tooltip: "Job Info",
    },
    {
      color: "success",
      icon: "event-seat",
      clickAction: (e) => onClickHandler("jobinterviews", e),
      tooltip: "Job Interviews",
    },
    {
      color: "warning",
      icon: <PersonAddIcon />,
      clickAction: (e) => onClickHandler("jobapplicants", e),
      tooltip: "Job Applicants",
    },
    // {
    //   color: "error",
    //   icon: "people",
    //   clickAction: (e) => onClickHandler("jobstaffingpool", e),
    //   tooltip: "Job Staffing Pool",
    // },
    {
      color: "success",
      icon: "badge",
      clickAction: (e) => onClickHandler("jobstatus", e),
      tooltip: "Job Status",
    },
  ]);

  return (
    <MDBox display="flex" justifyContent="end">
      {actions.map((prop) => (
        <TableAction {...prop} />
      ))}
    </MDBox>
  );
};

export default MyJobsActions;
