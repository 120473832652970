import React from "react";
import {
  Box,
  IconButton,
  Grid,
  Typography,
  Autocomplete,
  Stack,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextareaAutosize,
  Checkbox,
} from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import FormField from "layouts/applications/wizard/components/FormField";
import { NumericFormat } from "react-number-format";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import MDTypography from "components/MDTypography";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const SortableCheckbox = ({ field, handleToggleEnabled, disableSort, disabled }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: field.id,
  });

  const style = React.useMemo(
    () => ({
      transform: CSS.Transform.toString(transform),
      transition: transform ? transition : undefined,
    }),
    [transform, transition]
  );

  return (
    <Grid item xs={12} md={6} ref={setNodeRef} style={style}>
      <Box
        display="flex"
        flexDirection="column"
        p={2}
        border="1px solid #ddd"
        borderRadius="8px"
        bgcolor="#fff"
        sx={{
          position: "relative",
          transform: isDragging ? "scale(1.02)" : "scale(1)",
          zIndex: isDragging ? 999 : 1,
          opacity: isDragging ? 0.9 : 1,
          boxShadow: isDragging ? "0 8px 16px rgba(0, 0, 0, 0.1)" : "none",
          transition: "transform 150ms ease, box-shadow 150ms ease",
          willChange: "transform",
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography
            variant="subtitle1"
            fontWeight={500}
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              color: isDragging ? "#1976d2" : "inherit",
            }}
          >
            {field.label}
          </Typography>
          {!disableSort && (
            <Box display="flex" gap={1} sx={{ opacity: isDragging ? 0.7 : 1 }}>
              <IconButton
                {...listeners}
                {...attributes}
                sx={{
                  cursor: isDragging ? "grabbing" : "grab",
                  p: 0.5,
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                  },
                }}
              >
                <DragIndicatorIcon />
              </IconButton>
            </Box>
          )}
        </Box>
        <Stack direction="row" alignItems="center" display="flex" minHeight="45.13px">
          <Checkbox
            id="custom-checkbox"
            disabled={disabled}
            checked={field.enabled}
            onChange={(e) => handleToggleEnabled(e, field.id)}
            sx={{ opacity: disabled ? 0.5 : 1 }}
          />
          <MDTypography variant="body2" pl={1} opacity={disabled ? 0.5 : 1}>
            Enabled
          </MDTypography>
        </Stack>
      </Box>
    </Grid>
  );
};

export default SortableCheckbox;
